import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Discord } from "@styled-icons/simple-icons";
import { Farcaster, TwitterX } from "@assets/Logos";
import {
  Container,
  ContainerContent,
  EqualHStack,
  VerticalStack,
  DeviceShowType,
  Spacer,
} from "@components/Layout";
import { IconLink } from "@components/Links";
import { LogoTypeImage } from "@src/components/Image";
import { SubscribeForm } from "@components/SubscribeForm";

const FooterContainer = styled(Container)`
  background: ${({ theme }) => theme.colors.navBgColor};
  padding: 80px 24px;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 80px;
  }
`;

const FooterLogoTypeImage = styled(LogoTypeImage)``;

const FooterBlurb = styled.p`
  margin-top: 12px;

  a {
    text-decoration: none;
    font-family: "Mundial Regular", sans-serif;
    font-size: 16px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      color: ${({ theme }) => theme.colors.lighterBlue};
    }
  }
`;

const SubscribeVStack = styled(VerticalStack)`
  align-items: stretch;

  @media ${({ theme }) => theme.breakpoints.md} {
    align-items: center;
  }
`;

const SubscribeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SubscribeTitle = styled.h1`
  width: 100%;
  text-align: left;
  font-weight: 500;
  margin-bottom: 8px;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const FooterIconLink = styled(IconLink)`
  margin-right: 24px;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
    height: 24px;
    width: 24px;
  }

  &:hover {
    cursor: pointer;

    svg {
      fill: ${({ theme }) => theme.colors.lighterBlue};
    }
  }
`;

const GradientHatContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0px;
`;

const GradientHat = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 275px;
  background: linear-gradient(
    to top,
    rgba(173, 226, 255, 0.17),
    rgba(173, 226, 255, 0)
  );
`;

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(path);
  };

  return (
    <>
      <GradientHatContainer>
        <GradientHat></GradientHat>
      </GradientHatContainer>
      <FooterContainer>
        <EqualHStack>
          <ContainerContent>
            <FooterLogoTypeImage src="/images/wordmark.svg" />
            <FooterBlurb>
              Check in monthly to build your own art collection! Art should be
              accessible for everyone to celebrate. That’s why we make it simple
              for everyone from across the globe to begin their own art
              collecting journey.
            </FooterBlurb>
            <FooterBlurb>
              Product of{" "}
              <a href="http://kusari.org" target="_blank" rel="noreferrer">
                Kusari
              </a>
            </FooterBlurb>
            <FooterBlurb>
              <a
                href="#"
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  e.preventDefault();
                  handleNavigate("/terms");
                }}
              >
                Terms of Service
              </a>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <a
                href="#"
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  e.preventDefault();
                  handleNavigate("/privacy");
                }}
              >
                Privacy Policy
              </a>
            </FooterBlurb>
          </ContainerContent>
          <Spacer $deviceShowType={DeviceShowType.MOBILEONLY} />
          <ContainerContent>
            <SubscribeVStack>
              <SubscribeContainer>
                <SubscribeTitle>Subscribe</SubscribeTitle>
                <SubscribeForm compact />
                <IconsContainer>
                  <FooterIconLink href="https://x.com/collectrs">
                    <TwitterX />
                  </FooterIconLink>
                  <FooterIconLink href="https://warpcast.com/collectr">
                    <Farcaster />
                  </FooterIconLink>
                  <FooterIconLink href="https://discord.com/invite/uwucrew">
                    <Discord />
                  </FooterIconLink>
                </IconsContainer>
              </SubscribeContainer>
            </SubscribeVStack>
          </ContainerContent>
        </EqualHStack>
      </FooterContainer>
    </>
  );
};

export default Footer;
