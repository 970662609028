import { useState, useEffect } from "react";
import styled from "styled-components";
import Select, {
  components,
  OptionProps,
  SingleValueProps,
  OnChangeValue,
} from "react-select";
import {
  base,
  mainnet,
  abstract,
  arbitrum,
  optimism,
  zora,
  blast,
  baseSepolia,
  sepolia,
  arbitrumSepolia,
  abstractTestnet,
  optimismSepolia,
  zoraSepolia,
  scroll,
  zksync,
  zksyncSepoliaTestnet,
  scrollSepolia,
} from "viem/chains";
import { useAccount, useSwitchChain, useBalance } from "wagmi";
import { Info } from "@styled-icons/octicons";
import { Modal } from "@components/Modal";
import {
  AlignType,
  VerticalStack,
  HorizontalStack,
  ContainerContent,
} from "@components/Layout";
import { Callout } from "@components/Text";
import { ModalButton } from "@components/Buttons";
import { IS_MAINNET } from "@utils/constants";
import {
  ETH,
  Base,
  OP,
  Arbitrum,
  Zora,
  Blast,
  ZkSync,
  Scroll,
  Abstract,
} from "@assets/NetworkLogos";
import { ModalContainerContent, PoweredByText } from "./styled";
import { parseEther } from "viem";
import { CrossmintPayButton_DEPRECATED as CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { useLatestEdition } from "@src/context";
import ETHUsdPrice from "@src/pages/Edition/components/ETHUsdPrice";

interface PaymentMethodModalProps {
  isOpen: boolean;
  onClose: () => void;
  instanceId: number;
  handlePayment: (selectedChain: number, quantity: number, performTX: boolean) => void;
  url: string;
}

const StyledSelect = styled(Select)<any>`
  width: 100%;
  margin-bottom: 16px;
`;

const StyledSelectHStack = styled(HorizontalStack)`
  svg {
    margin-top: auto;
    margin-bottom: auto;
  }
  img {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const CrossmintContainer = styled.div`
  width: 100%;
  justify-content: flex-start;

  .crossmint-pay-button {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lessRoyalBlue};
  }

  .crossmintParagraph-0-2-3 {
    visibility: hidden;
  }

  .crossmint-pay-button:hover,
  .crossmintButton-0-2-1:hover:enabled {
    cursor: pointer;
    opacity: 0.6;
  }

  .crossmintParagraph-0-2-3:before {
    content: "Credit Card";
    position: block;
    visibility: visible;
    font-size: 18px;
    font-weight: 500;
  }

  .crossmintImg-0-2-2,
  .crossmintImg-1-2-2 {
    margin-right: 0.5rem;
    content: url("/images/credit-card.svg");
  }
`;

const MintWithETHContainer = styled.div`
  width: 100%;
  justify-content: flex-start;

  div {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lessRoyalBlue};
  }

  button {
    display: flex;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 15px;
    transition: opacity 0.25s ease-in-out 0s;
    align-items: center;
    border: 0px;
    border-radius: 0.5rem;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    background-color: #04458d;
    margin-bottom: 16px;
    color: #fff;
  }

  span {
    content: "Credit Card";
    position: block;
    visibility: visible;
    font-size: 18px;
    font-weight: 500;
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }

  img {
    margin-top: auto;
    margin-left: 0.4rem;
    margin-bottom: auto;
    margin-right: 0.25rem;
    content: url("/images/eth-logo.svg");
  }

  button:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const NetworkName = styled.strong<{ disabled?: boolean }>`
  font-size: 20px;
  color: ${(props) => (props.disabled ? "#bbb" : "#333333")};
`;

const Balance = styled.div<{ disabled?: boolean }>`
  font-size: 0.8em;
  color: ${(props) => (props.disabled ? "#bbb" : "#333333")};
`;

const CollectionInfo = styled.div`
  background-color: #0d3159;
  border-radius: 12px;
  padding: 12px;
  border: 1px solid #ffffff90;
  display: flex;
  justify-content: space-between;
`

const CollectingText = styled.p`
  color: #ffffff;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 0px;
  min-width: 200px;
`

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const ItemName = styled.div`
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin-top: 4px;
`

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  justify-content: center;
  min-width: 80px;
`

const UsdPrice = styled.div`
  color: #9b9b9b;
  font-size: 14px;
  margin-bottom: 2px;
  font-family: monospace;
`

const EthPrice = styled.div`
  color: #ffffff;
  font-size: 20px;
  font-family: monospace;
`

const QuantitySelector = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
`

const QuantityButton = styled.button<{ selected?: boolean }>`
  background-color: ${(props) => (props.selected ? "#00438f" : "#0d3159")};
  color: #ffffff;
  border: 1px solid #19559a;
  border-radius: 8px;
  padding: 12px 0;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  width: 100%;

  &:hover {
    background-color: ${(props) => (props.selected ? "#19559a" : "#19559a")};
    color: #ffffff;
  }
`

const CustomQuantityInput = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px;
  align-items: center;
`

const QuantityControl = styled.button`
  background-color: #00438f;
  color: #ffffff;
  border: 1px solid #00438f;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  padding: 8px;

  &:hover {
    background-color: #003366;
  }
`

const NumberInput = styled.input`
  background-color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 8px;
  font-size: 18px;
  text-align: center;
  width: 100%;
  color: #000000;

  &:focus {
    outline: none;
  }
`

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "8px",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "8px",
    padding: "0px 8px",
    boxSizing: "border-box",
  }),
  option: (provided, state) => ({
    ...provided,
    borderRadius: "8px",
    padding: "8px",
    margin: "2px 0px",
    boxSizing: "border-box",
  }),
};

const minimum = IS_MAINNET ? parseEther("0.005") : parseEther("0.0000005");

const options = IS_MAINNET
  ? [base, mainnet, arbitrum, abstract, optimism, zksync, blast, scroll, zora]
  : [
      baseSepolia,
      sepolia,
      arbitrumSepolia,
      abstractTestnet,
      optimismSepolia,
      zksyncSepoliaTestnet,
      scrollSepolia,
      zoraSepolia,
    ];

const chainLogoMap: { [key: number]: React.ComponentType<any> } = {
  [mainnet.id]: ETH,
  [base.id]: Base,
  [arbitrum.id]: Arbitrum,
  [abstract.id]: Abstract,
  [optimism.id]: OP,
  [zksync.id]: ZkSync,
  [blast.id]: Blast,
  [scroll.id]: Scroll,
  [zora.id]: Zora,
  [sepolia.id]: ETH,
  [baseSepolia.id]: Base,
  [arbitrumSepolia.id]: Arbitrum,
  [abstractTestnet.id]: Abstract,
  [optimismSepolia.id]: OP,
  [zksyncSepoliaTestnet.id]: ZkSync,
  [scrollSepolia.id]: Scroll,
  [zoraSepolia.id]: Zora,
};

const Option = (props: OptionProps) => {
  const { address } = useAccount();
  const { data } = props;
  const Logo = chainLogoMap[data.id] || ETH;

  const { data: balanceData } = useBalance({
    address,
    chainId: data.id,
  });

  const isDisabled = balanceData?.value < minimum;

  return (
    <components.Option {...props} isDisabled={isDisabled}>
      <StyledSelectHStack>
        <Logo />
        <div>
          <NetworkName disabled={isDisabled}>{data.name}</NetworkName>
          <Balance disabled={isDisabled}>
            Balance: {parseFloat(balanceData?.formatted || 0).toFixed(4)} ETH
          </Balance>
        </div>
      </StyledSelectHStack>
    </components.Option>
  );
};

const SingleValue = (props: SingleValueProps) => {
  const { address } = useAccount();
  const { data } = props;
  const Logo = chainLogoMap[data.id] || ETH;

  const { data: balanceData } = useBalance({
    address,
    chainId: data.id,
  });

  return (
    <components.SingleValue {...props}>
      <StyledSelectHStack>
        <Logo />
        <div>
          <strong>{data.name}</strong>
          <Balance>
            Balance: {parseFloat(balanceData?.formatted || 0).toFixed(4)} ETH
          </Balance>
        </div>
      </StyledSelectHStack>
    </components.SingleValue>
  );
};

const PaymentMethodModal = ({
  isOpen,
  onClose,
  instanceId,
  handlePayment,
  url,
}: PaymentMethodModalProps) => {
  const { address, chain } = useAccount();
  const { chains, switchChain } = useSwitchChain();
  const [ethCheckout, setEthCheckout] = useState(false);
  let defaultChain = chain || base;
  const [selectedChain, setSelectedChain] = useState(defaultChain);
  const [isConnectedToSelectedChain, setIsConnectedToSelectedChain] =
    useState(false);

  const latestEdition = useLatestEdition();

  const { data: balanceData } = useBalance({
    address,
    chainId: selectedChain.id,
  });

  const [quantity, setQuantity] = useState(1)
  const [isCustom, setIsCustom] = useState(false)
  const [customValue, setCustomValue] = useState(3)
  const [ethPrice, setEthPrice] = useState(0)
  const pricePerUnit = 0.01; // Price per mint in ETH
  const totalPrice = (quantity * pricePerUnit).toFixed(3);

  useEffect(() => {
    if (chain && selectedChain) {
      if (balanceData?.value < minimum) {
        setIsConnectedToSelectedChain(false);
      } else {
        setIsConnectedToSelectedChain(chain.id === selectedChain.id);
      }
    }
  }, [chain, selectedChain]);

  useEffect(() => {
    if (chain) {
      const connectedChain = options.find((option) => option.id === chain.id);
      if (connectedChain) {
        setSelectedChain(connectedChain);
      }
    }
  }, [chain]);

  const onChange = (value: OnChangeValue<OptionType, false>) => {
    setSelectedChain(value);
  };

  const handleButtonClick = () => {
    if (isConnectedToSelectedChain) {
      handlePayment(selectedChain.id, quantity, true);
    } else {
      switchChain?.({ chainId: selectedChain.id });
    }
  };

  const onModalClose = () => {
    setEthCheckout(false);
    setQuantity(1);
    onClose();
  };

  const handleEthCheckoutClick = () => {
    setEthCheckout(true);
  };

  const handleCreditCardClick = () => {
    handlePayment(defaultChain.id, 1, false);
  };

  // useEffect(() => {
  //   fetch("/api/eth-price")
  //     .then((res) => res.json())
  //     .then((data) => setEthPrice(data.price))
  //     .catch((error) => console.error("Failed to fetch ETH price:", error))
  // }, [])

  const handleQuantityChange = (value: number | "custom") => {
    if (value === "custom") {
      setIsCustom(true)
      setQuantity(customValue)
    } else {
      setIsCustom(false)
      setQuantity(value as number)
    }
  }

  const handleCustomValueChange = (newValue: number) => {
    const value = Math.min(Math.max(1, newValue), 1000);
    setCustomValue(value)
    setQuantity(value)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}
      title={ethCheckout ? "Checkout" : "Select Checkout"}
      overflowY={false}
    >
      <ContainerContent>
        <HorizontalStack>
          {ethCheckout ? (
            <VerticalStack>
              {/* <ModalContainerContent>
                <Callout>
                  <Info size="16" />
                  &nbsp; Pay from any network, receive on Base
                </Callout>
              </ModalContainerContent> */}

        <CollectionInfo>
          <ItemInfo>
            <CollectingText>You are collecting {isCustom ? customValue : quantity} of:</CollectingText>
            <ItemName>{latestEdition.editionPage.name}</ItemName>
          </ItemInfo>
          <PriceContainer>
            <ETHUsdPrice quantity={isCustom ? customValue : quantity} />
            <EthPrice>Ξ{(0.005 * (isCustom ? customValue : quantity)).toFixed(3)}</EthPrice>
          </PriceContainer>
        </CollectionInfo>

        <QuantitySelector>
          <ButtonGroup>
            <QuantityButton selected={!isCustom && quantity === 1} onClick={() => handleQuantityChange(1)}>
              1
            </QuantityButton>
            <QuantityButton selected={!isCustom && quantity === 2} onClick={() => handleQuantityChange(2)}>
              2
            </QuantityButton>
            <QuantityButton selected={isCustom} onClick={() => handleQuantityChange("custom")}>
              Custom
            </QuantityButton>
          </ButtonGroup>

          {isCustom && (
            <CustomQuantityInput>
              <QuantityControl onClick={() => handleCustomValueChange(customValue - 1)} aria-label="Decrease quantity">
                -
              </QuantityControl>
              <NumberInput
                type="number"
                value={customValue}
                onChange={(e) => handleCustomValueChange(Number.parseInt(e.target.value) || 1)}
                min="1"
                max="1000"
              />
              <QuantityControl onClick={() => handleCustomValueChange(customValue + 1)} aria-label="Increase quantity">
                +
              </QuantityControl>
            </CustomQuantityInput>
          )}
        </QuantitySelector>

              {/* <YouAreCollectingContainer>
                <small>You are collecting:</small>
                <HorizontalStack>
                  <div>
                    <strong>Hello Base! x{quantity}</strong>
                  </div>
                  <div>
                    <strong>Ξ {totalPrice}</strong> ($
                    {(totalPrice * 3500).toFixed(2)})
                  </div>
                </HorizontalStack>
              </YouAreCollectingContainer>

              <PresetButtons>
                <HorizontalStack isWrap={false}>
                  <button
                    key={1}
                    className={preset === 1 ? "selected" : ""}
                    onClick={() => handlePresetClick(1)}
                  >
                    1
                  </button>
                  <button
                    key={2}
                    className={preset === 2 ? "selected" : ""}
                    onClick={() => handlePresetClick(2)}
                  >
                    2
                  </button>
                  <button
                    className={preset === "custom" ? "selected" : ""}
                    onClick={() => handlePresetClick("custom")}
                  >
                    Custom
                  </button>
                </HorizontalStack>
              </PresetButtons>

              <QuantitySelector>
                <HorizontalStack>
                  <button
                    onClick={() => handleQuantityChange(quantity - 1)}
                    disabled={quantity <= 1}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    value={quantity}
                    min={1}
                    max={1000}
                    onChange={(e) =>
                      handleQuantityChange(Number(e.target.value))
                    }
                  />
                  <button
                    onClick={() => handleQuantityChange(quantity + 1)}
                    disabled={quantity >= 1000}
                  >
                    +
                  </button>
                </HorizontalStack>
              </QuantitySelector> */}

              <StyledSelect
                components={{ Option, SingleValue }}
                options={options}
                getOptionLabel={(option: OptionType) => option.name}
                getOptionValue={(option: OptionType) => option.id}
                isSearchable={true}
                onChange={onChange}
                defaultValue={selectedChain}
                styles={customStyles}
              />

              <ModalButton
                disabled={!balanceData?.value || (balanceData.value < (isCustom ? customValue : quantity) * (IS_MAINNET ? 0.005 : 0.0000005))}
                onClick={handleButtonClick}
              >
                {isConnectedToSelectedChain ? "Collect" : (balanceData.value < (isCustom ? customValue : quantity) * (IS_MAINNET ? 0.005 : 0.0000005)) ? "Not enough ETH" : "Switch Network"}
              </ModalButton>
            </VerticalStack>
          ) : null}
          {ethCheckout ? null : (
            <VerticalStack>
              <MintWithETHContainer>
                <button onClick={handleEthCheckoutClick}>
                  <img />
                  <span>Mint with ETH</span>
                </button>
              </MintWithETHContainer>
              {IS_MAINNET ? (
                <CrossmintContainer>
                  <CrossmintPayButton
                    collectionId="bf847436-705a-428b-99d8-85841d96e52d"
                    projectId="c3df53c1-9d4f-4220-a18f-83ea91a2f7bf"
                    mintConfig={{
                      totalPrice: "0.005",
                      instanceId: `${instanceId}`,
                      mintCount: 1,
                    }}
                    mintTo={address}
                    onClick={handleCreditCardClick}
                    checkoutProps={{
                      experimental: true,
                      display: "same-tab",
                      paymentMethods: ["fiat"],
                    }}
                    className="crossmint-pay-button"
                    successCallbackURL={url}
                    failureCallbackURL={url}
                  />
                </CrossmintContainer>
              ) : (
                <CrossmintContainer>
                  <CrossmintPayButton
                    collectionId="e035debd-cc1c-414b-809c-0749edb56d27"
                    projectId="2ee7c748-7fa4-4851-8047-071aae375ba7"
                    mintConfig={{ totalPrice: "0.0000005", instanceId: `${0}` }}
                    mintTo={address}
                    environment="staging"
                    onClick={handleCreditCardClick}
                    checkoutProps={{
                      experimental: true,
                      display: "same-tab",
                      paymentMethods: ["fiat"],
                    }}
                    className="crossmint-pay-button"
                    successCallbackURL={url}
                    failureCallbackURL={url}
                  />
                </CrossmintContainer>
              )}
            </VerticalStack>
          )}
        </HorizontalStack>
      </ContainerContent>
    </Modal>
  );
};

export default PaymentMethodModal;
