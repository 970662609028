import styled, { css } from "styled-components";

export const Headline = styled.h1`
  color: ${({ theme }) => theme.colors.fgColor};
  font-family: "Mudial Black", sans-serif;
  font-size: ${({ theme }) => `calc(${theme.fontSizes.headline} * .6)`};
  line-height: ${({ theme }) => `calc(${theme.fontSizes.headline} * .6)`};
  font-weight: 1000;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.x2};

  @media ${({ theme }) => theme.breakpoints.md} {
    color: ${({ theme }) => theme.colors.fgColor};
    text-align: left;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    font-size: ${({ theme }) => `calc(${theme.fontSizes.headline} * .75)`};
    line-height: ${({ theme }) => `calc(${theme.fontSizes.headline} * .75)`};
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    font-size: ${({ theme }) => theme.fontSizes.headline};
    line-height: ${({ theme }) => theme.fontSizes.headline};
  }

  span {
    ${css`
      font-size: inherit;
      font-weight: inherit;
    `}

    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const EmHeadline = styled(Headline)`
  font-family: "Mudial Regular", sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  text-align: left;
`;

export const DropHead = styled.div`
  color: ${({ theme }) => theme.colors.fgColor};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: 400;
  line-height: 28px;
  max-width: 520px;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.x4};

  @media ${({ theme }) => theme.breakpoints.md} {
    color: ${({ theme }) => theme.colors.fgColor};
    text-align: left;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;

  @media ${({ theme }) => theme.breakpoints.md} {
    align-items: center;
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.fgColor};
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: 1000;
  white-space: nowrap;

  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: ${({ theme }) => theme.fontSizes.title};
  }
`;

export const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.secondaryFgColor};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  margin-bottom: ${({ theme }) => theme.spacing.x4};

  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: ${({ theme }) => theme.fontSizes.subtitle};
  }
`;

export const Paragraph = styled.p`
  max-width: 600px;
  font-size: ${({ theme }) => theme.fontSizes.normal};
  text-align: center;
`;

export const Overline = styled.h3`
  color: ${({ theme }) => theme.colors.grey40};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.fontSizes.small};
  font-weight: 900;
  text-transform: uppercase;
`;

export const Callout = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.royalBlue};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  padding: ${({ theme }) => theme.spacing.x1} ${({ theme }) => theme.spacing.x2};
  margin: 0px 0px ${({ theme }) => theme.spacing.x2} 0px;
  border-radius: 8px;
  display: flex;

  svg {
    position: relative;
    margin-top: 4px;
    margin-right: 4px;
    fill: ${({ theme }) => theme.colors.primary};
  }
  
  p {
      margin-bottom: 0px;
  }
`;

export const CleanCallout = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.royalBlue};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  padding: ${({ theme }) => theme.spacing.x1} ${({ theme }) => theme.spacing.x2};
  margin: 0px 0px 0px 0px;
  border-radius: 8px;

  svg {
    position: relative;
    top: -1px;
    fill: ${({ theme }) => theme.colors.primary};
  }
`;
