import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { http } from "wagmi";

import {
  createClient,
  convertViemChainToRelayChain,
  LogLevel,
  TESTNET_RELAY_API,
  MAINNET_RELAY_API,
} from "@reservoir0x/relay-sdk";
import { abstract, abstractTestnet, arbitrum, arbitrumSepolia, base, baseSepolia, blast, mainnet, optimism, optimismSepolia, scroll, scrollSepolia, sepolia, zksync, zksyncSepoliaTestnet, zora, zoraSepolia } from "viem/chains";
import { IS_MAINNET } from "./utils/constants";
import {
  metaMaskWallet,
  coinbaseWallet,
  rainbowWallet,
  walletConnectWallet
} from '@rainbow-me/rainbowkit/wallets';

declare module "wagmi" {
  interface Register {
    config: typeof wagmiConfig;
  }
}

export const relayConfig = createClient({
  baseApiUrl: IS_MAINNET ? MAINNET_RELAY_API : TESTNET_RELAY_API,
  source: "https://collectr.live",
  chains: IS_MAINNET ? [
    convertViemChainToRelayChain(base),
    convertViemChainToRelayChain(mainnet),
    convertViemChainToRelayChain(arbitrum),
    convertViemChainToRelayChain(abstract),
    convertViemChainToRelayChain(optimism),
    convertViemChainToRelayChain(zksync),
    convertViemChainToRelayChain(blast),
    convertViemChainToRelayChain(scroll),
    convertViemChainToRelayChain(zora),
  ] : [
    convertViemChainToRelayChain(baseSepolia),
    convertViemChainToRelayChain(sepolia),
    convertViemChainToRelayChain(arbitrumSepolia),
    convertViemChainToRelayChain(abstractTestnet),
    convertViemChainToRelayChain(optimismSepolia),
    convertViemChainToRelayChain(zoraSepolia),
    convertViemChainToRelayChain(zksyncSepoliaTestnet),
    convertViemChainToRelayChain(scrollSepolia),
  ],
  logLevel: LogLevel.Info,
});

export const wagmiConfig = getDefaultConfig({
  appName: `${process.env.REACT_APP_WALLETCONNECT_APPNAME}`,
  projectId: `${process.env.REACT_APP_WALLETCONNECT_PROJECTID}`,
  chains: IS_MAINNET ? [base, mainnet, arbitrum, abstract, optimism, zksync, blast, scroll, zora]
    : [baseSepolia, sepolia, arbitrumSepolia, abstractTestnet, optimismSepolia, zksyncSepoliaTestnet, scrollSepolia, zoraSepolia],
  transports: IS_MAINNET ? {
    [base.id]: http(
      `https://base-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_MAINNET}`
    ),
    [mainnet.id]: http(
      `https://eth-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_MAINNET}`
    ),
    [arbitrum.id]: http(
      `https://arb-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_MAINNET}`
    ),
    [abstract.id]: http(
      `https://abstract-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_MAINNET}`
    ),
    [optimism.id]: http(
      `https://opt-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_MAINNET}`
    ),
    [zksync.id]: http(
      `https://zksync-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_MAINNET}`
    ),
    [blast.id]: http(
      `https://blast-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_MAINNET}`
    ),
    [scroll.id]: http(
      `https://scroll-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_MAINNET}`
    ),
    [zora.id]: http(
      `https://zora-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_MAINNET}`
    ),
  } : {
    [baseSepolia.id]: http(
      `https://base-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_SEPOLIA}`
    ),
    [sepolia.id]: http(
      `https://eth-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_SEPOLIA}`
    ),
    [arbitrumSepolia.id]: http(
      `https://arb-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_SEPOLIA}`
    ),
    [abstractTestnet.id]: http(
      `https://abstract-testnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_SEPOLIA}`
    ),
    [optimismSepolia.id]: http(
      `https://opt-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_SEPOLIA}`
    ),
    [zksyncSepoliaTestnet.id]: http(
      `https://zksync-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_SEPOLIA}`
    ),
    [scrollSepolia.id]: http(
      `https://scroll-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_SEPOLIA}`
    ),
    [zoraSepolia.id]: http(
      `https://zora-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_SEPOLIA}`
    ),
  },
  wallets: [{
    groupName: "Wallets",
    wallets: [
      metaMaskWallet,
      walletConnectWallet,
      coinbaseWallet,
      rainbowWallet,
    ]
  }]
});
