import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-toastify";
import {
  useConnectModal,
  useChainModal,
  getDefaultWallets,
  getWalletConnectConnector,
} from "@rainbow-me/rainbowkit";
import { getWalletClient } from "@wagmi/core";
import {
  useAccount,
  useWriteContract,
  useWaitForTransactionReceipt,
  useReadContract,
  useWalletClient,
  useGasPrice,
  usePublicClient,
} from "wagmi";
import ReactCountryFlag from "react-country-flag";
import { getName } from "country-list";
import {
  ethToWei,
  formatTimeLeft,
  isValidYearAndMonth,
  monthMapLongToShort,
  monthMapShortToLong,
  titleize,
} from "@utils/utils";
import { IS_MAINNET } from "@utils/constants";
import { abi } from "@contracts/CollectorV2";
import { ercABI } from "@contracts/ERC1155Creator";
import {
  Link,
  ScreenFull,
  Paintbrush,
  Mail,
  Globe,
  LinkExternal,
  Info,
  Check,
} from "@styled-icons/octicons";
import {
  Instagram,
  Discord,
  Opensea,
  Artstation,
  Telegram,
} from "@styled-icons/simple-icons";
import { Foundation, Sealed, TwitterX } from "@assets/Logos";
import { Mobile, PC, SmartWatch } from "@assets/Icons";

import {
  AlignType,
  DeviceShowType,
  Spacer,
  Container,
  ContainerContent,
  ContainerFlexContent,
  HorizontalStack,
  EqualHStack,
  VerticalStack,
} from "@components/Layout";
import { EmHeadline } from "@components/Text";
import { InfoCard } from "@components/Cards";
import { Footer } from "@components/Footer";
import {
  FullScreenOverlay,
  EditionImageContainer,
  EditionImage,
  EditionFullScreenImage,
  ImageButtonContainer,
  ImageButton,
  EditionTitleContainer,
  EditionInfoContainer,
  Year,
  Artist,
  Price,
  CollectButton,
  TimeRemaining,
  Infolet,
  SplitInfolet,
  SplitOpenIcon,
  SplitImagesContainer,
  SplitImage,
  InfoTitle,
  InfoSubline,
  InfoLinkContainer,
  SkillPillContainer,
  SkillPill,
  CommunityPfp,
  MintMark,
  MintedContainer,
  Description,
  IconLinkContainer,
  ExtraInfoContainer,
  WallpaperOpenIcon,
  YouOwnText,
  SupplyTextContainer,
} from "./styled";
import { editions, getDefaultEdition } from "@apis/editions";
import { useLatestEdition } from "@context/EditionsProvider";
import TransactionModal from "./components/TransactionModal";
import SplitModal from "./components/SplitModal";
import BenefitsModal from "./components/BenefitsModal";
import PaymentMethodModal from "./components/PaymentMethodModal";
import { EditionIconLink } from "./components/styled";
import { analytics, logEvent } from "../../firebase";
import { wagmiConfig } from "../../config";

import { getClient, adaptViemWallet } from "@reservoir0x/relay-sdk";
import { base, baseSepolia, sepolia } from "viem/chains";
import { estimateContractGas, simulateContract } from "viem/actions";
import {
  ContractFunctionExecutionError,
  EstimateGasExecutionError,
  TransactionExecutionError,
  BaseError,
  createPublicClient,
  http
} from 'viem'
import { parseEther } from "viem";
import { Base, Blast, ETH, OP, Zora } from "@src/assets/NetworkLogos";
import WallpaperModal from "./components/WallpaperModal";

const EditionContainer = styled(Container)`
  opacity: 1;
  transition: opacity 0.5s linear;

  &.loading {
    opacity: 0;
  }
`;

const CONTRACT_CHAIN = IS_MAINNET ? base : baseSepolia;
const COLLECTR_CONTRACT = IS_MAINNET
  ? process.env.REACT_APP_CONTRACT_ADDRESS
  : process.env.REACT_APP_TEST_CONTRACT_ADDRESS;
const NFT_CONTRACT = IS_MAINNET
  ? process.env.REACT_APP_NFT_ADDRESS
  : process.env.REACT_APP_TEST_NFT_ADDRESS;

const contractChainClient = createPublicClient({
  chain: CONTRACT_CHAIN,
  transport: http(
    IS_MAINNET 
      ? `https://base-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_MAINNET}`
      : `https://base-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_SEPOLIA}`

  )
})

const Edition = () => {
  let { year, month } = useParams<{ year: string; month: string }>();
  month = month?.toLowerCase();
  if (month && month in monthMapLongToShort) {
    month = monthMapLongToShort[month];
  }
  const navigate = useNavigate();
  const parsedYear = year ? parseInt(year, 10) : NaN;
  const latestEdition = useLatestEdition();

  useEffect(() => {
    if (isNaN(parsedYear) || !isValidYearAndMonth(parsedYear, month || "")) {
      navigate("/404");
    }
  }, [parsedYear, month, navigate]);

  const [totalMinted, setTotalMinted] = useState(0);

  const [transactionModalOpen, setTransactionModalOpen] = useState(false);
  const [splitModalOpen, setSplitModalOpen] = useState(false);
  const [wallpaperModalOpen, setWallpaperModalOpen] = useState(false);
  const [benefitsModalOpen, setBenefitsModalOpen] = useState(false);
  const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);

  const [modalStatus, setModalStatus] = useState<
    "pending" | "success" | "error"
  >("pending");
  const [modalTitle, setModalTitle] = useState("");
  const [modalImage, setModalImage] = useState<string | null>(null);
  const [modalMessage, setModalMessage] = useState("");

  const [isCollectButtonDisabled, setIsCollectButtonDisabled] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isConcluded, setIsConcluded] = useState(false);

  const handleOpenTransactionModal = () => {
    setTransactionModalOpen(true);
  };
  const handleCloseTransactionModal = () => {
    setTransactionModalOpen(false);
    setIsCollectButtonDisabled(false);
  };
  const handleOpenSplitModal = () => {
    setSplitModalOpen(true);
  };
  const handleOpenWallpaperModal = () => {
    setWallpaperModalOpen(true);
  };
  const handleCloseSplitModal = () => {
    setSplitModalOpen(false);
  };
  const handleCloseWallpaperModal = () => {
    setWallpaperModalOpen(false);
  };
  const handleOpenBenefitsModal = () => {
    setBenefitsModalOpen(true);
  };
  const handleCloseBenefitsModal = () => {
    setBenefitsModalOpen(false);
  };
  const handleOpenPaymentMethodModal = () => {
    setPaymentMethodModalOpen(true);
  };
  const handleClosePaymentMethodModal = () => {
    setPaymentMethodModalOpen(false);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const copyPageUrlToClipboard = async () => {
    try {
      const url = window.location.href;
      await navigator.clipboard.writeText(url);
      toast.success("Copied edition URL to clipboard!", {
        position: "bottom-right",
        className: "custom-toast",
      });
    } catch (error) {
      console.error("Failed to copy URL: ", error);
    }
  };

  const {
    data: edition = getDefaultEdition(),
    // isLoading,
    // isError,
    // error,
  } = useQuery({
    queryKey: ["edition", year, month],
    queryFn: () =>
      editions
        .index()
        .then((data) =>
          data.find(
            (edition) =>
              edition.year.toString() === year && edition.month === month
          )
        ),
    enabled: !!year && !!month,
  });

  const { data: total, refetch: refetchTotal } = useReadContract({
    address: NFT_CONTRACT as `0x${string}`,
    abi: ercABI,
    functionName: "totalSupply",
    args: [BigInt(IS_MAINNET ? edition.nftId : 2)],
    chainId: CONTRACT_CHAIN.id,
  });

  useEffect(() => {
    if (!!total) {
      setTotalMinted(Number(total));
    }
  }, [total]);

  useEffect(() => {
    refetchTotal();
    setIsConcluded(formatTimeLeft(edition.endTime) === "Concluded");
  }, [edition]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { openConnectModal } = useConnectModal();
  const { openChainModal } = useChainModal();
  const { isConnected, connector, address, chain, chainId } = useAccount();
  const { data: walletClient, isLoading, isError } = useWalletClient();
  const {
    data: hash,
    error,
    isPending,
    writeContractAsync,
  } = useWriteContract();
  const { data: gasPrice } = useGasPrice({
    config: wagmiConfig,
    chainId: CONTRACT_CHAIN.id,
  });
  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  const { data: totalOwnedMints, refetch: refetchTotalOwnedMints } = useReadContract({
    chainId: CONTRACT_CHAIN.id, // needs to be mainnet
    address: NFT_CONTRACT as `0x${string}`,
    abi: ercABI,
    functionName: "balanceOf",
    args: [address as `0x${string}`, IS_MAINNET ? BigInt(edition.nftId) : BigInt(2)],
  });

  useEffect(() => {
    // console.log("chain", chain);
  }, [chain]);

  const handleCollect = () => {
    if (isConnected) {
      if (analytics) {
        logEvent(analytics, "collect_clicked", {
          edition: `${edition.year}/${edition.month}`,
        });
      }
      handleOpenPaymentMethodModal();
    } else {
      if (openConnectModal) {
        if (analytics) {
          logEvent(analytics, "wallet_connected", {
            edition: `${edition.year}/${edition.month}`,
          });
        }
        openConnectModal();
      }
    }
  };

  const handlePayment = (selectedId, quantity, performTX) => {
    submittingModal();

    if (!performTX) {
      return;
    }

    const totalValue = (IS_MAINNET ? parseEther("0.005") : parseEther("0.0000005")) * BigInt(quantity)

    if (selectedId !== CONTRACT_CHAIN.id) {
      const client = getClient();
      client?.actions
        .getQuote({
          chainId: selectedId,
          toChainId: CONTRACT_CHAIN.id,
          currency: "0x0000000000000000000000000000000000000000",
          toCurrency: "0x0000000000000000000000000000000000000000",
          amount: totalValue.toString(),
          tradeType: "EXACT_OUTPUT",
          wallet: adaptViemWallet(walletClient),
          txs: [
            {
              chain: CONTRACT_CHAIN,
              address: COLLECTR_CONTRACT as `0x${string}`,
              abi,
              functionName: "mint",
              account: walletClient.account,
              args: [address as `0x${string}`, BigInt(IS_MAINNET ? edition.instanceId : 0), BigInt(quantity)],
              value: totalValue,
            }
          ],
        })
        .then((quote) => {
          getClient()
            .actions.execute({
              quote,
              wallet: adaptViemWallet(walletClient),
              onProgress: ({
                steps,
                fees,
                breakdown,
                currentStep,
                currentStepItem,
                txHashes,
                details,
              }) => {
                if (currentStepItem?.progressState === "validating") {
                  confirmingModal();
                }
              },
            })
            .then((uhh) => {
              confirmedModal();
              if (analytics) {
                logEvent(analytics, "mint_success", {
                  chain: chain?.name,
                  edition: `${edition.year}/${edition.month}`,
                });
              }
            })
            .catch((err) => {
              processError(err);
            });
        })
        .then((uhh) => {})
        .catch((err) => {
          console.log("quote fail", err);
          toast.error("Unable to receive quote from Relay", {
            position: "bottom-right",
            className: "custom-toast",
          });
        });
    } else {
      writeContractAsync({
        address: COLLECTR_CONTRACT as `0x${string}`,
        abi,
        functionName: "mint",
        value: totalValue,
        args: [address as `0x${string}`, BigInt(edition.instanceId), quantity], //TODO - update to multi
        // args: [
        //   "0x08D816526BdC9d077DD685Bd9FA49F58A5Ab8e48",
        //   BigInt(edition.instanceId),
        // ],
      })
        .then((uhh) => {
          if (analytics) {
            logEvent(analytics, "mint_success", {
              chain: chain?.name,
              edition: `${edition.year}/${edition.month}`,
            });
          }
          console.log("natural mint success", uhh);
        })
        .catch((err) => {
          console.log("natural mint fail", err);
        });
    }
  };

  const submittingModal = () => {
    handleClosePaymentMethodModal();
    setModalStatus("pending");
    setModalTitle("Collecting...");
    setModalMessage(`Waiting for your transaction...`);
    setModalImage(null);
    handleOpenTransactionModal();
    setIsCollectButtonDisabled(true);
  };

  const confirmingModal = () => {
    handleClosePaymentMethodModal();
    setModalStatus("pending");
    setModalTitle("Collecting...");
    setModalMessage(`Transaction submitted! Confirming...`);
    setModalImage(null);
    handleOpenTransactionModal();
    setIsCollectButtonDisabled(true);
  };

  const confirmedModal = () => {
    setModalStatus("success");
    setModalTitle("✨Collected!");
    setModalMessage(`${edition.editionPage.name} is now in your collection!`);
    setModalImage(`${edition.projectInfo.imageURL}`);
    handleOpenTransactionModal();
    setTimeout(refetchTotalOwnedMints, 300);
    setTimeout(refetchTotal, 300);
    setIsCollectButtonDisabled(false);
  };

  const processError = (error) => {
    if (
      error.message.includes("User rejected the request.") ||
      error.message.includes("User denied transaction signature.")
    ) {
      toast.error("Cancelled transaction", {
        position: "bottom-right",
        className: "custom-toast",
      });
    } else {
      toast.error("An error occurred :(", {
        position: "bottom-right",
        className: "custom-toast",
      });
      console.log(
        `An error occurred during the transaction. ${error.message}.`,
        error
      );
    }
    handleCloseTransactionModal();
    setIsCollectButtonDisabled(false);
  };

  useEffect(() => {
    if (hash && isConfirming) {
      confirmingModal();
    }
    if (isConfirmed) {
      confirmedModal();
      if (analytics) {
        logEvent(analytics, "mint_success", {
          edition: `${edition.year}/${edition.month}`,
        });
      }
    }

    if (error) {
      processError(error);
    }
  }, [
    hash,
    isConfirming,
    isConfirmed,
    error,
    edition.editionPage.name,
    edition.projectInfo.imageURL,
  ]);

  const handleSwitchChain = () => {
    if (openChainModal) openChainModal();
  };

  useEffect(() => {
    if (edition.editionPage.name === "") {
      return;
    }

    const url = new URL(window.location.href);
    // Extract the 'p' parameter from the URL
    const encodedParam = url.searchParams.get("p");

    if (!!encodedParam) {
      // URL decode and parse the JSON string
      const decodedJson = decodeURIComponent(encodedParam);
      const jsonData = JSON.parse(decodedJson);

      console.log(jsonData);
      if (
        jsonData[0].type === "purchase.succeeded" &&
        jsonData[0].status === "success"
      ) {
        confirmedModal();
        // Update the URL without reloading the page
        window.history.replaceState({}, "", `${window.location.pathname}`);
      }
    }
  }, [edition.editionPage.name, edition.projectInfo.imageURL]);

  return (
    <>
      {isFullScreen && (
        <FullScreenOverlay onClick={toggleFullScreen}>
          <EditionFullScreenImage
            src={edition.projectInfo.imageURL}
            alt="Full screen"
            className="full-screen"
          />
        </FullScreenOverlay>
      )}
      <EditionContainer className={edition.instanceId === -1 ? "loading" : ""}>
        <ContainerContent>
          <Spacer $deviceShowType={DeviceShowType.MOBILEONLY} />
          <HorizontalStack>
            <EditionImageContainer>
              <EditionImage
                src={edition.projectInfo.imageURL}
                onClick={toggleFullScreen}
              ></EditionImage>
              <ImageButtonContainer>
                <ImageButton
                  onClick={() =>
                    window.open(edition.editionPage.openseaUrl, "_blank")
                  }
                >
                  <Opensea size="24" />
                </ImageButton>
                <ImageButton onClick={copyPageUrlToClipboard}>
                  <Link size="24" />
                </ImageButton>
                <ImageButton onClick={toggleFullScreen}>
                  <ScreenFull size="24" />
                </ImageButton>
              </ImageButtonContainer>
            </EditionImageContainer>
            <EditionInfoContainer align="start" $alignType={AlignType.CENTER}>
              <EditionTitleContainer>
                <Year>
                  {edition.month} {edition.year}
                </Year>
                <EmHeadline>{edition.editionPage.name}</EmHeadline>
                <Artist>
                  artist: {`${edition.artistInfo.name} `}
                  {edition.artistInfo.flag != "SEA" ? (
                    <ReactCountryFlag
                      countryCode={edition.artistInfo.flag}
                      svg
                      style={{ width: "1.5em", height: "1.5em" }}
                      title={getName(edition.artistInfo.flag)}
                    />
                  ) : (
                    `🌏`
                  )}
                </Artist>
              </EditionTitleContainer>
              <p>{edition.editionPage.nftDescription}</p>
              <Spacer />
              <EqualHStack columns={2} $noMobileCollapse={true}>
                <Price>
                  Ξ <em>{edition.editionPage.price}</em>
                </Price>
                <ContainerContent $alignType={AlignType.RIGHT}>
                  <SupplyTextContainer>
                    {totalMinted} collected

                  </SupplyTextContainer>
                </ContainerContent>
              </EqualHStack>
              {isConcluded ? (
                <CollectButton onClick={handleCollect} disabled>
                  edition concluded
                </CollectButton>
              ) : (
                <CollectButton
                  onClick={handleCollect}
                  disabled={isCollectButtonDisabled}
                >
                  {isCollectButtonDisabled
                    ? "Confirming..."
                    : Number(totalOwnedMints) && Number(totalOwnedMints) > 0 
                    ? "Collect More" 
                    : "Collect"}
                </CollectButton>
              )}
              {
                Number(totalOwnedMints) && Number(totalOwnedMints) > 0 ? (
                  <CollectButton onClick={handleOpenBenefitsModal} $benefits={true} >
                    View Benefits
                  </CollectButton>
                ) : null
              }
              {Number(totalOwnedMints) && Number(totalOwnedMints) > 0 ? (
                <MintedContainer>
                  <MintMark>
                    <Check size="16" />
                  </MintMark>
                  <TimeRemaining>
                    You own {Number(totalOwnedMints)}, come back for next month's edition!
                  </TimeRemaining>
                </MintedContainer>
              ) : (
                <MintedContainer>
                  {isConcluded ? (
                    <TimeRemaining>
                      {titleize(edition.month)} {edition.year} edition has
                      concluded, but check out the{" "}
                      {latestEdition ? (
                        <a
                          href={`/${latestEdition.year}/${latestEdition.month}`}
                        >
                          current edition!
                        </a>
                      ) : (
                        "current edition!"
                      )}
                    </TimeRemaining>
                  ) : (
                    <TimeRemaining>
                      {formatTimeLeft(edition.endTime)} remaining
                    </TimeRemaining>
                  )}
                </MintedContainer>
              )}
              <EqualHStack>
                <Infolet
                  onClick={
                    edition.benefits.showcaseURL
                      ? handleOpenWallpaperModal
                      : null
                  }
                >
                  <h2>Wallpapers</h2>
                  {edition.benefits.showcaseURL ? (
                    <WallpaperOpenIcon>
                      <LinkExternal size="24" />
                    </WallpaperOpenIcon>
                  ) : null}
                  <EqualHStack $noMobileCollapse={true}>
                    <PC />
                    <Mobile />
                    <SmartWatch />
                  </EqualHStack>
                </Infolet>
                <SplitInfolet onClick={handleOpenSplitModal}>
                  <h2>Split</h2>
                  <SplitOpenIcon>
                    <LinkExternal size="24" />
                  </SplitOpenIcon>
                  <SplitImagesContainer>
                    <SplitImage
                      src={edition.editionPage.splitImages[0]}
                      className="image"
                    />
                    <SplitImage
                      src={edition.editionPage.splitImages[1]}
                      className="image"
                    />
                    <SplitImage
                      src={edition.editionPage.splitImages[2]}
                      className="image"
                    />
                  </SplitImagesContainer>
                </SplitInfolet>
              </EqualHStack>
              <TransactionModal
                isOpen={transactionModalOpen}
                onClose={handleCloseTransactionModal}
                status={modalStatus}
                message={modalMessage}
                title={modalTitle}
                image={modalImage}
              />
              <BenefitsModal
                tag={`${edition.year}/${edition.month}`}
                isOpen={benefitsModalOpen}
                onClose={handleCloseBenefitsModal}
                benefitsCID={edition.benefits.allWallpapersCID}
              />
              <PaymentMethodModal
                isOpen={paymentMethodModalOpen}
                onClose={handleClosePaymentMethodModal}
                instanceId={edition.instanceId}
                handlePayment={handlePayment}
                url={`https://${window.location.hostname}/${edition.year}/${edition.month}`}
              />
              {/* Start Buttons for simulating modal states
              <div style={{ marginTop: "20px" }}>
                <button
                  onClick={() => {
                    setModalStatus("pending");
                    setModalTitle("Collecting...");
                    setModalImage(null);
                    setModalMessage("Confirming your new collection");
                    handleOpenTransactionModal();
                  }}
                  style={{ marginRight: "10px" }}
                >
                  Simulate Pending
                </button>
                <button
                  onClick={() => {
                    setModalStatus("success");
                    setModalTitle("✨Collected!");
                    setModalImage(`${edition.projectInfo.imageURL}`);
                    setModalMessage(
                      `${edition.editionPage.name} is now in your collection!`
                    );
                    handleOpenTransactionModal();
                  }}
                  style={{ marginRight: "10px" }}
                >
                  Simulate Success
                </button>
                <button
                  onClick={() => {
                    handleOpenBenefitsModal();
                  }}
                >
                  Simulate Check Benefits
                </button>
              </div>
              End Buttons for simulating modal states */}
              <SplitModal
                isOpen={splitModalOpen}
                onClose={handleCloseSplitModal}
                splitData={edition.split}
                artistName={edition.artistInfo.name}
                communityName={edition.projectInfo.communityName}
              />
              {wallpaperModalOpen && (
                <FullScreenOverlay
                  onClick={handleCloseWallpaperModal}
                  limitWidth
                >
                  <EditionFullScreenImage
                    src={edition.benefits.showcaseURL}
                    alt="Wallpaper preview"
                    className="full-screen"
                  />
                </FullScreenOverlay>
              )}
            </EditionInfoContainer>
          </HorizontalStack>
          <Spacer />
          <Spacer />
          <EqualHStack columns={2} $noMobileCollapse={false}>
            <InfoCard
              title={
                <>
                  <Paintbrush size="24" /> Illustrated By
                </>
              }
            >
              <InfoLinkContainer>
                <InfoTitle>
                  {`${edition.artistInfo.name} `}
                  {edition.artistInfo.flag != "SEA" ? (
                    <ReactCountryFlag
                      countryCode={edition.artistInfo.flag}
                      svg
                      style={{ width: "2em", height: "2em" }}
                      title={getName(edition.artistInfo.flag)}
                    />
                  ) : (
                    `🌏`
                  )}
                </InfoTitle>
              </InfoLinkContainer>

              <IconLinkContainer>
                <ContainerFlexContent $alignType={AlignType.RIGHT}>
                  <HorizontalStack>
                    <EditionIconLink href={edition.artistInfo.twitter}>
                      <TwitterX />
                    </EditionIconLink>

                    {edition.artistInfo.instagram && (
                      <EditionIconLink href={edition.artistInfo.instagram}>
                        <Instagram />
                      </EditionIconLink>
                    )}
                    <EditionIconLink
                      href={`mailto:${edition.artistInfo.email}`}
                    >
                      <Mail />
                    </EditionIconLink>
                    {edition.artistInfo.foundation && (
                      <EditionIconLink
                        href={edition.artistInfo.foundation}
                        svgWidth={30}
                      >
                        <Foundation />
                      </EditionIconLink>
                    )}
                    {edition.artistInfo.sealed && (
                      <EditionIconLink href={edition.artistInfo.sealed}>
                        <Sealed />
                      </EditionIconLink>
                    )}
                    {edition.artistInfo.opensea && (
                      <EditionIconLink href={edition.artistInfo.opensea}>
                        <Opensea />
                      </EditionIconLink>
                    )}
                    {edition.artistInfo.portfolio && (
                      <EditionIconLink href={edition.artistInfo.portfolio}>
                        <Artstation />
                      </EditionIconLink>
                    )}
                  </HorizontalStack>
                </ContainerFlexContent>
              </IconLinkContainer>

              <Description>{`${edition.artistInfo.description}`}</Description>

              <InfoLinkContainer>
                <VerticalStack style={{margin: 0}}>
                  <InfoSubline>Skills:</InfoSubline>
                  <SkillPillContainer>
                    {edition.artistInfo.skills.map((skill, index) => (
                      <SkillPill key={index}>{skill}</SkillPill>
                    ))}
                  </SkillPillContainer>
                </VerticalStack>
              </InfoLinkContainer>
            </InfoCard>
            <InfoCard
              title={
                <>
                  <Info size="24" /> In Collaboration With
                </>
              }
            >
              <InfoLinkContainer>
                <InfoTitle>{edition.projectInfo.communityName}</InfoTitle>
              </InfoLinkContainer>

              <IconLinkContainer>
                <ContainerFlexContent $alignType={AlignType.RIGHT}>
                  <HorizontalStack>
                    <EditionIconLink
                      href={edition.projectInfo.communityTwitter}
                    >
                      <TwitterX />
                    </EditionIconLink>
                    {edition.projectInfo.communityInstagram && (
                      <EditionIconLink
                        href={edition.projectInfo.communityInstagram}
                      >
                        <Instagram />
                      </EditionIconLink>
                    )}
                    <EditionIconLink
                      href={edition.projectInfo.communityWebsite}
                    >
                      <Globe />
                    </EditionIconLink>
                    {edition.projectInfo.communityOpensea ? (
                      <EditionIconLink
                        href={edition.projectInfo.communityOpensea}
                      >
                        <Opensea />
                      </EditionIconLink>
                    ) : null}
                    {edition.projectInfo.communityDiscord ? (
                      <EditionIconLink
                        href={edition.projectInfo.communityDiscord}
                      >
                        <Discord />
                      </EditionIconLink>
                    ) : null}
                    {edition.projectInfo.communityTelegram ? (
                      <EditionIconLink
                        href={edition.projectInfo.communityTelegram}
                      >
                        <Telegram />
                      </EditionIconLink>
                    ) : null}
                  </HorizontalStack>
                </ContainerFlexContent>
              </IconLinkContainer>
              <Description>{edition.projectInfo.description}</Description>
              {edition.projectInfo.communityMembers.length > 0 ? (
                <InfoSubline>Featured Community Members:</InfoSubline>
              ) : null}
              <ExtraInfoContainer>
                {edition.projectInfo.communityMembers.map((member, index) => (
                  <a href={member.twitter} target="_blank" rel="noreferrer">
                    <CommunityPfp
                      key={index}
                      src={member.image}
                      alt={`${member.name} (${member.character})`}
                    />
                  </a>
                ))}
              </ExtraInfoContainer>
            </InfoCard>
          </EqualHStack>
          <Spacer />
        </ContainerContent>
      </EditionContainer>
      <Footer />
    </>
  );
};

export default Edition;
