import styled from "styled-components";

import { Button } from "@components/Buttons";
import { TitleContainer } from "@src/components/Text";
import { HorizontalStack, VerticalStack } from "@src/components/Layout";

export const FullScreenOverlay = styled.div<{ limitWidth?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;

  img {
    @media ${({ theme }) => theme.breakpoints.md} {
      ${({ limitWidth }) => limitWidth && `max-width: 75%;`}
    }
    border-radius: 0;
  }
`;

export const EditionImageContainer = styled.div`
  max-width: 100%;
  position: relative;
  border-radius: ${({ theme }) => theme.radii.x4};
  padding: 0px;
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: flex-end;

  @media ${({ theme }) => theme.breakpoints.md} {
    display: inline-block;
    height: 75vh;
    position: relative;
    margin-right: ${({ theme }) => theme.spacing.x8};
    top: calc(12.5vh - ${({ theme }) => theme.sizes.navbarHeight});
    max-width: 50%;
    max-height: 75%;
  }
`;

export const EditionImage = styled.img`
  max-width: 100%;
  border-radius: ${({ theme }) => theme.radii.x4};
  object-fit: contain;

  @media ${({ theme }) => theme.breakpoints.md} {
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const EditionFullScreenImage = styled(EditionImage)`
  border: 0;
  box-shadow: none;
`;

export const ImageButtonContainer = styled.div`
  position: relative;
  height: 0;
  top: -140px;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ImageButton = styled(Button)`
  padding: 4px;
  border-radius: 4px;
  margin-top: 8px;

  background: ${({ theme }) => theme.colors.fgColor};
  border: 1px solid ${({ theme }) => theme.colors.grey40};

  svg {
    margin: 0;
    height: auto;
    fill: ${({ theme }) => theme.colors.grey60};
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.offWhite};
    }
  }
`;

export const EditionTitleContainer = styled(TitleContainer)`
  align-items: start;
`;

export const EditionInfoContainer = styled(VerticalStack)`
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.md} {
    max-width: 50%;
  }
`;

export const Year = styled.div`
  color: ${({ theme }) => theme.colors.lighterBlue};
  font-family: "Mundial Regular", sans-serif;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;

  @media ${({ theme }) => theme.breakpoints.md} {
    color: ${({ theme }) => theme.colors.lighterBlue};
    font-size: 24px;
  }
`;

export const Artist = styled.div`
  color: #ade2ff;
  font-size: 18px;
  margin-bottom: 24px;
`;

export const Price = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;

  em {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.lighterBlue};
  }
`;

export const CollectButton = styled(Button)<{ $benefits?: boolean }>`
  padding-left: 64px;
  padding-right: 64px;
  width: 100%;

  background-color: ${({ $benefits }) => $benefits ? "#00438F" : "#00b4ff"};
  border-width: 0px;
  margin-top: ${({ $benefits }) => $benefits ? "12px" : "0px"};

  &:hover {
    background-color: ${({ $benefits }) => $benefits ? "#19559a" : "#35c2ff"} !important;
    color: #fff;

    svg {
      fill: #fff;
    }
  }

  &:disabled {
    cursor: not-allowed;

    &:hover {
        background-color: ${({ $benefits }) => $benefits ? "#19559a" : "#35c2ff"} !important;
        fill: #fff;
      }
    }
  }
`;

export const TimeRemaining = styled.p`
  color: #ade2ff;
  text-align: left;
  width: 100%;
  margin: 8px 0px 24px 0px;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};

  a {
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
    font-family: "Mundial Regular", sans-serif;
    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      color: ${({ theme }) => theme.colors.lighterBlue};
    }
  }
`;

export const SupplyTextContainer = styled.div`
  margin-bottom: 8px;
  height: 100%;
  align-content: center;
`;

export const YouOwnText = styled.p`
  width: auto;
  margin: 0;
  margin-left: 8px;
  display: inline;
  color: #ade2ff;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const WallpaperOpenIcon = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const Infolet = styled(VerticalStack)`
  background: ${({ theme }) => theme.colors.secondaryBgColor};
  padding: 8px 8px 8px 8px;
  border-radius: 10px;

  h2 {
    margin-bottom: 12px;
    text-align: center;
  }

  position: relative;
  &:hover {
    cursor: ${({ onClick }) => (!!onClick ? "pointer" : "auto")};

    .image {
      &:nth-child(1) {
        transform: translateX(-20px);
      }
      &:nth-child(2) {
        transform: translateX(0);
      }
      &:nth-child(3) {
        transform: translateX(20px);
      }
    }

    ${WallpaperOpenIcon} {
      svg {
        fill: ${({ theme }) => theme.colors.lessRoyalBlue};
      }
    }
  }
`;

export const WallpaperHeader = styled.h2`
  margin-bottom: 14px;
`;

export const SplitOpenIcon = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const SplitInfolet = styled(Infolet)`
  position: relative;
  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
  &:hover {
    cursor: pointer;

    .image {
      &:nth-child(1) {
        transform: translateX(-20px);
      }
      &:nth-child(2) {
        transform: translateX(0);
      }
      &:nth-child(3) {
        transform: translateX(20px);
      }
    }

    ${SplitOpenIcon} {
      svg {
        fill: ${({ theme }) => theme.colors.lessRoyalBlue};
      }
    }
  }
`;

export const SplitImagesContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SplitImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: -10px;
  border: 1px;
  border-color: #ade2ff;
  transition: transform 1s;
`;

export const InfoTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  color: ${({ theme }) => theme.colors.royalBlue};
  font-weight: 700;
`;

export const InfoSubline = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-right: auto;
  font-weight: 700;
`;

export const InfoLinkContainer = styled(HorizontalStack)`
  margin-bottom: 4px;
  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const ExtraInfoContainer = styled(HorizontalStack)`
  padding-top: 4px;
`;

export const IconLinkContainer = styled(InfoLinkContainer)`
  margin-bottom: 8px;
`;

export const SkillPillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SkillPill = styled.div`
  display: inline-block;
  padding: 4px 8px;
  margin: 4px 8px 0 0;
  color: ${({ theme }) => theme.colors.fgColor};
  background-color: ${({ theme }) => theme.colors.lessRoyalBlue};
  border-radius: 8px;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  font-weight: 800;
  white-space: nowrap;
`;

export const Description = styled.p`
  white-space: pre-wrap;
  margin-top: 8px;
`;

export const CommunityPfp = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 8px;
  border-color: ${({ theme }) => theme.colors.primary};
  border-width: 2px;
  border-style: solid;

  &:hover {
    cursor: pointer;
  }
`;

export const MintedContainer = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
`;

export const MintMark = styled.div`
  background: #11c690;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-right: 2px;

  svg {
    fill: #000;
  }
`;
