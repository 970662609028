import { IEdition } from "@src/context";

export const editions_data = [
  {
    nftId: 1,
    instanceId: 0,
    startTime: 1719792000,
    endTime: 1722470340,
    month: "jul",
    year: 2024,
    split: {
      collector: 45,
      artist: 30,
      project: 21,
      community: 4,
    },
    editionPage: {
      price: "0.01",
      name: "Hello Base! 🔵✨",
      nftDescription:
        "As a natural beginning for Collectr, this art piece by Pawwao represents uwucrew, a community of people who are passionate about art and Web3. Her colorful accessories are filled with all kinds of fun references. The uwu peace sign is greeting among those who understand!✌️✌️",
      splitImages: [
        "https://pbs.twimg.com/profile_images/1853954632944386055/14rDdTpb_400x400.png",
        "https://pbs.twimg.com/profile_images/1612838137688121345/4AXGWkG0_400x400.jpg",
        "https://pbs.twimg.com/profile_images/1768341464394711040/DS5NbivZ_400x400.jpg",
      ],
      openseaUrl:
        "https://opensea.io/assets/base/0x335a975b8122f982b0d4db9d7256e24852f0d502/1",
    },
    artistInfo: {
      name: "Pawwao",
      description:
        "From a young age, Pawwao was always drawn to filling the empty pages of her textbooks with doodles. Today, she uses her art to express her creativity and connect to the rest of the world from Thailand. She has won countless Web3 art contests, and her art has been showcased in Akihabara, Japan!\nFor over 3 years her vibrant illustrations and talent for storytelling have brought joy to the uwucrew community. She is also part of a Thai NFT Art collective named BlueMeow! It's a pleasure to have her for the first Collectr edition.",
      flag: "TH",
      twitter: "https://x.com/pawwaoart",
      instagram: "https://www.instagram.com/pawwaoart",
      email: "pawwaoart@gmail.com",
      foundation: "https://foundation.app/@pawwao",
      sealed: "https://sealed.art/0x26aeab9bc6630bf8e155770ab314ec5f8169edb5",
      portfolio: "https://arbum.art/u/uwaaacafe/artworks",
      skills: ["Character Illustration", "Character Design"],
    },
    projectInfo: {
      imageURL:
        "https://storage.googleapis.com/collectr-assets/july/original-compressed.png",
      communityName: "uwucrew",
      communityTwitter: "https://x.com/uwucrewnft",
      communityDiscord: "https://discord.com/invite/uwucrew",
      communityWebsite: "https://uwucrew.art",
      communityOpensea: "https://opensea.io/collection/uwucrew",
      description:
        "At uwucrew, using Web3 to empower artists from all over the world is what we live for. The uwucrew NFT community contains some of the largest art collectors, as well as over 250 global artists.\nJoin us as we celebrate the launch of Collectr! We're making it easy for anyone to build an NFT art collection at their own pace. Our Onchain Summer on Base begins with this edition and we look forward to making a creative impact on Web3!",
      communityMembers: [
        {
          character: "uwucrew #6389",
          image:
            "https://i.seadn.io/gcs/files/6ea01168947d3404db61f72eb33c2b62.png?auto=format&dpr=1&w=1000",
          name: "Cloudy Zan",
          twitter: "https://x.com/cloudyzan",
        },
        {
          character: "uwucrew #4658",
          image:
            "https://i.seadn.io/gcs/files/a76b517513e09982a9d7785e7b26d4bf.png?auto=format&dpr=1&w=1000",
          name: "Feiriena",
          twitter: "https://x.com/Feireina",
        },
        {
          character: "uwucrew #3976",
          image:
            "https://i.seadn.io/gcs/files/8cdd6ea619593f7a883345957a926818.png?auto=format&dpr=1&w=1000",
          name: "Ligaratus",
          twitter: "https://x.com/ligaratus_",
        },
        {
          character: "uwucrew #1792",
          image:
            "https://i.seadn.io/gcs/files/0d4beb18f707f55ac479e164f2dfdcc3.png?auto=format&dpr=1&w=1000",
          name: "uwunate",
          twitter: "https://x.com/nuwuate",
        },
      ],
      hasWallpapers: true,
    },
    benefits: {
      allWallpapersCID: "bafybeihj5sqww5blhmv5agakwsjvq545idlacd637hbu7voxm4r7ao6r4y",
      showcaseURL: "",
    },
  },
  {
    nftId: 2,
    instanceId: 1,
    startTime: 1722470400,
    endTime: 1725148799,
    month: "aug",
    year: 2024,
    split: {
      collector: 45,
      artist: 30,
      project: 21,
      community: 4,
    },
    editionPage: {
      price: "0.01",
      name: "Killer Evening ❤️⚔️",
      nftDescription:
        "In her hidden sanctuary, Cerise awaits a new target on her burner phone. Selecting the perfect disguise for every mission is part of her lethal strategy: the very skill that makes her the uncatchable assassin.",
      splitImages: [
        "https://pbs.twimg.com/profile_images/1853954632944386055/14rDdTpb_400x400.png",
        "https://pbs.twimg.com/profile_images/1616546984101580800/jFBiI78u_400x400.jpg",
        "https://pbs.twimg.com/profile_images/1477847166978695168/zqdjDEMe_400x400.jpg",
      ],
      openseaUrl:
        "https://opensea.io/assets/base/0x335a975b8122f982b0d4db9d7256e24852f0d502/2",
    },
    artistInfo: {
      name: "Okami",
      description:
        "Okami works as a concept artist in the gaming industry, he is from China and specializes in character and environment design. During his graduate studies he worked as a freelance illustrator, he provided artwork for interactive novels and animations.\nAfter graduation, Okami joined a seasoned game development company where he continues to apply his expertise in concept art. He has also been participating within Web3 for almost 2 years, his stunning art has already made an impact within the Killer GF community!",
      flag: "CN",
      twitter: "https://twitter.com/OkamiL_Art",
      instagram: "https://www.instagram.com/harveyli56/",
      email: "harvey9256@gmail.com",
      foundation: "https://foundation.app/@okami_l",
      portfolio: "https://www.artstation.com/harveyli",
      skills: ["Concept Art", "Character Illustration"],
    },
    projectInfo: {
      imageURL:
        "https://storage.googleapis.com/collectr-assets/august/original-august-2024-compressed.png",
      communityName: "Killer GF",
      communityTwitter: "https://x.com/killergfnft",
      communityInstagram: "https://instagram.com/killergfnft",
      communityDiscord: "https://discord.com/invite/killergfnft",
      communityWebsite: "https://killergf.com",
      communityOpensea: "https://opensea.io/collection/killergf",
      description:
        "Launched in January 2022, Killer GF is regarded as one of the highest quality anime NFT collections. Illustrated by League of Legends concept artist Zeronis, KGF depicts 7,777 unique disguises of Cerise, a skilled assassin.\nWith over 21,000 ETH in trading volume on OpenSea, KGF has consistently rewarded holders through 30 consecutive months of art airdrops. They also created KGF Cyber Run, a web-based action game offering exclusive benefits to NFT owners.",
      communityMembers: [
        {
          character: "Killer GF #6381",
          image: "https://storage.googleapis.com/kgf-thumbnails/6381.jpg",
          name: "Brighty",
          twitter: "https://x.com/BrightAvian",
        },
        {
          character: "Killer GF #7128",
          image: "https://storage.googleapis.com/kgf-thumbnails/7128.jpg",
          name: "Liangyu",
          twitter: "https://x.com/chenlia48120395",
        },
        {
          character: "Killer GF #2162",
          image: "https://storage.googleapis.com/kgf-thumbnails/2162.jpg",
          name: "Ally",
          twitter: "https://x.com/CryptoAllyy",
        },
        {
          character: "Killer GF #2180",
          image: "https://storage.googleapis.com/kgf-thumbnails/2180.jpg",
          name: "BBrown",
          twitter: "https://x.com/BBrown9506",
        },
      ],
      hasWallpapers: true,
    },
    benefits: {
      allWallpapersCID: "bafybeib3zpisnqdvsneqo7dsewkvgcvqsc7oluipt7scu5xjrhsqz2a4yi",
      showcaseURL: "",
    },
  },
  {
    nftId: 3,
    instanceId: 2,
    startTime: 1725148800,
    endTime: 1727740799,
    month: "sep",
    year: 2024,
    split: {
      collector: 48,
      artist: 30,
      project: 18,
      community: 4,
    },
    editionPage: {
      price: "0.01",
      name: "Based Bus Ride 😺🚍️",
      nftDescription:
        "All aboard the based bus! The tubbies are gathering everyone to get ready for their silly onchain adventure to Base! As the clowder heads towards the bridge, they enjoy some snacks and have deep philosophical conversations. Oh, wait a minute... we forgot someone! turn back nya!!!",
      splitImages: [
        "https://pbs.twimg.com/profile_images/1853954632944386055/14rDdTpb_400x400.png",
        "https://pbs.twimg.com/profile_images/1759864639951958016/409DfGOz_400x400.jpg",
        "https://pbs.twimg.com/profile_images/1510384146937483267/P1zQFGyD_400x400.jpg",
      ],
      openseaUrl:
        "https://opensea.io/assets/base/0x335a975b8122f982b0d4db9d7256e24852f0d502/3",
    },
    artistInfo: {
      name: "tani",
      description:
        "Tani is an artist from Japan with a talent for character design in a cutesy style. She has made a massive mark in the NFT space, being known as a fan-favorite artist within the tubby cats community! \n\nShe uses art to express herself and draw things she thinks are cute! She loves drawing girls, ribbons, frills, and maids, and more. Her soft and dreamy style draws inspiration from all things cute, including Sanrio, shoujo manga, and moe culture. Her work has even been showcased at maid-themed exhibits in Akihabara, Japan!",
      flag: "JP",
      twitter: "https://x.com/tani_fancy",
      email: "tani.fancy@gmail.com",
      opensea: "https://opensea.io/tani_fancy/created",
      foundation: "https://foundation.app/@tani",
      skills: ["Character Design", "Character Illustration"],
    },
    projectInfo: {
      imageURL:
        "https://storage.googleapis.com/collectr-assets/september-2024/original-september-2024-compressed.png",
      communityName: "tubby cats",
      communityTwitter: "https://x.com/tubbycatsnft",
      communityDiscord: "https://discord.gg/tubbycatsnft",
      communityWebsite: "https://tubbycats.xyz/home",
      communityOpensea: "https://opensea.io/collection/tubby-cats",
      description:
        "tubby cats is a vibey NFT collection of 20,000 silly little cats, known for their community's strong presence and fun culture! Their iconic mascots and colorful traits were designed by artist friends who go by Sweetbread, Hoppy, Bandage, Sugoi, and Dino.\n\nTheir team is working on exciting efforts like 3D models + 2D sprites for all tubbys, as well a line of plushies! Make sure to tune in to their weekly Tubby Tuesdays and enjoy a fresh cup of pilk (pepsi + milk). Nya nya!",
      communityMembers: [
        {
          character: "tubby cat #705",
          image: "https://storage.googleapis.com/collectr-assets/september-2024/character-thumbnails/chilly.png",
          name: "Chilly",
          twitter: "https://x.com/Chilli",
        },
        {
          character: "tubby cat #6995",
          image: "https://storage.googleapis.com/collectr-assets/september-2024/character-thumbnails/kiliko.png",
          name: "Kiliko",
          twitter: "https://x.com/Ki1iko",
        },
        {
          character: "tubby cat jin",
          image: "https://storage.googleapis.com/collectr-assets/september-2024/character-thumbnails/dankvr.png",
          name: "dankvr",
          twitter: "https://x.com/dankvr",
        },
        {
          character: "tubby cat #7888",
          image: "https://storage.googleapis.com/collectr-assets/september-2024/character-thumbnails/cryptonite.png",
          name: "cryptonite",
          twitter: "https://twitter.com/0xcryptonite",
        },
      ],
      hasWallpapers: true,
    },
    benefits: {
      allWallpapersCID: "bafybeia5deqpqt4xohcjbjogaobiyxhygg5qbrwtqxhk2zskxprcx6sb2i",
      showcaseURL: "",
    },
  },
  {
    nftId: 4,
    instanceId: 3,
    startTime: 1727740800,
    endTime: 1730419199,
    month: "oct",
    year: 2024,
    split: {
      collector: 48,
      artist: 30,
      project: 17,
      community: 5,
    },
    editionPage: {
      price: "0.01",
      name: "A:\\Kickflip💫",
      nftDescription:
        "Doing tricks in the Based skatepark, the Aikos are hanging out and skating around. This piece is to commemerate the recent addition of MAcci into the Aiko Virtual team!",
      splitImages: [
        "https://pbs.twimg.com/profile_images/1853954632944386055/14rDdTpb_400x400.png",
        "https://pbs.twimg.com/profile_images/1847978721120333824/Von55r7x_400x400.jpg",
        "https://pbs.twimg.com/profile_images/1664747737252872195/2PpkmxRt_400x400.jpg",
      ],
      openseaUrl:
        "https://opensea.io/assets/base/0x335a975b8122f982b0d4db9d7256e24852f0d502/4",
    },
    artistInfo: {
      name: "MAcci",
      description:
        "MAcci is a Japanese freelance artist who has rapidly become a superstar within the Aiko Virtual community. Using her experience in color design, she brilliantly illustrates characters with just three colors in her \"AikoPic\" collection, embodying her philosophy that \"any color can shine\".\n\nFrom humble onchain beginnings, MAcci has become a digital art rockstar. She's completed numerous commissions across multiple communities, has recently joined the Aiko Virtual team, and also developed her own IP, \"MAdoll\". Her impressive journey exemplifies the opportunities that participating in the onchain community make possible!",
      flag: "JP",
      twitter: "https://x.com/MAcciNFT",
      instagram: "https://www.instagram.com/macci_nft/",
      email: "79macci79@gmail.com",
      sealed: "https://sealed.art/0xa8a849b09aa9bf62782ab572f09ff479b76ebac8",
      portfolio: "https://sites.google.com/view/maccisite/top",
      skills: ["Character Design", "Character Illustration"],
    },
    projectInfo: {
      imageURL:
        "https://storage.googleapis.com/collectr-assets/october-2024/october-2024-compressed.jpg",
      communityName: "Aiko Virtual",
      communityTwitter: "https://x.com/aikovirtual",
      communityDiscord: "https://discord.gg/Rkkbf4j9mj",
      communityWebsite: "https://aikovirtual.com/",
      communityOpensea: "https://opensea.io/collection/aikovirtual",
      description:
        "Aiko Virtual combines elements of both eastern and western design philosophy into their NFT collection and brand. They innovate in onchain and physical products that compliment each other, building on both the virtual and reality! Their \"cyber cute\" aesthetic resonates within the hearts of the Aikommunity.\n\nMAcci has just recently joined the Aiko team as an artist, and to celebrate the team is creating physical prints for their holders! The team also has a history of delivering quality products, such as original art posters, t-shirts, pins, jewelry, and more.",
      communityMembers: [
        {
          character: "Aiko Virtual #1987",
          image: "https://storage.googleapis.com/collectr-assets/october-2024/character-thumbnails/starlin.jpg",
          name: "Starlin",
          twitter: "https://x.com/TokenWaifu",
        },
        {
          character: "Aiko Virtual #7953",
          image: "https://storage.googleapis.com/collectr-assets/october-2024/character-thumbnails/envy.jpg",
          name: "Envy",
          twitter: "https://twitter.com/envyoc",
        },
        {
          character: "Aiko Virtual #4463",
          image: "https://storage.googleapis.com/collectr-assets/october-2024/character-thumbnails/kehmi.jpg",
          name: "Kehmi",
          twitter: "https://x.com/kehmikal_",
        },
        {
          character: "Aiko Virtual #1294",
          image: "https://storage.googleapis.com/collectr-assets/october-2024/character-thumbnails/c4.jpg",
          name: "C4",
          twitter: "https://twitter.com/offgridc4",
        },
        {
          character: "Aiko Virtual #7452",
          image: "https://storage.googleapis.com/collectr-assets/october-2024/character-thumbnails/poisonfrog.jpg",
          name: "Poisonfrog",
          twitter: "https://twitter.com/PoisonFrogg",
        },
      ],
      hasWallpapers: true,
    },
    benefits: {
      allWallpapersCID: "bafybeig23wnsvmyajmghzhlz2dz7hjt52djm5k6qdqu3jig3iiag5sijpa",
      showcaseURL: "https://storage.googleapis.com/collectr-assets/october-2024/aiko-wallpapers.jpeg",
    },
  },
  {
    nftId: 5,
    instanceId: 4,
    startTime: 1730419200,
    endTime: 1733011199,
    month: "nov",
    year: 2024,
    split: {
      collector: 45,
      artist: 30,
      project: 25,
      splitsPage: "https://app.splits.org/accounts/0xF2D216596C73B74671988d62D73d7aB3a39C0AFB/?chainId=8453",
    },
    editionPage: {
      price: "0.01",
      name: "Onchain Duty ⛓️",
      nftDescription:
        "In celebration of Devcon 7 SEA, we honor the passionate developers and researchers who work endlessly behind the scenes. Their dedication to Ethereum is essential for all of us. Can you find all the easter eggs? Search for the top 10 PG donors, 3 Ethereum protocol features and 2 Thai landmarks!",
      splitImages: [
        "https://pbs.twimg.com/profile_images/1853954632944386055/14rDdTpb_400x400.png",
        "https://pbs.twimg.com/profile_images/1678446332158124047/vB0mmXCU_400x400.jpg",
        "https://pbs.twimg.com/profile_images/1529206083390255110/UkK0Hc6q_400x400.jpg",
      ],
      openseaUrl:
        "https://opensea.io/assets/base/0x335a975b8122f982b0d4db9d7256e24852f0d502/5",
    },
    artistInfo: {
      name: "SeekGoliath",
      description:
        "SeekGoliath is an illustrator and designer from Southeast Asia, his artistic talents have made an impact on many onchain projects including KaijuKingz, Killer GF and Sekai Glory! As a former street artist with a diverse background, he draws inspiration from tattoo art, anime, and more.\n\nThanks to his participation in the global onchain community, he has created his own IP and grew his presence by following his passion. Aside from working, Seek gets involved in web3 art contests, biking, and rescuing cats in his area!",
      flag: "SEA",
      twitter: "https://x.com/seekgoliath",
      instagram: "https://www.instagram.com/seekgoliath/",
      email: "seekgoliath@gmail.com",
      foundation: "https://foundation.app/@seekgoliath",
      skills: ["Character Design", "Character Illustration", "Emotes/Stickers"],
    },
    projectInfo: {
      imageURL:
        "https://storage.googleapis.com/collectr-assets/november-2024/november-seekgoliath-original.webp",
      communityName: "Protocol Guild",
      communityTwitter: "https://x.com/ProtocolGuild",
      communityDiscord: "",
      communityWebsite: "https://protocol-guild.readthedocs.io/en/latest/01-eligibility.html",
      description:
        "Protocol Guild enables direct funding for over 180 blockchain engineers/researchers who maintain and improve the digital infrastructure of Ethereum. The members of PG have collectively contributed 600+ years of their time towards essential improvements to Ethereum like The Merge, EIP-1559, EIP-4844, and more!\n\nEthereum is something we all use in some way, and have big aspirations for - supporting Protocol Guild serves as a credibly fair and flexible way to align the collective ecosystem towards the future improvement of Ethereum. The guild is self-managed by its collective, and they all help make Ethereum easier to use for everyone.",
      communityMembers: [],
      hasWallpapers: true,
    },
    benefits: {
      allWallpapersCID: "bafybeih4oc3oy5skodx5vy6yiyytpasny6djc5pim45dnqbvbuynqnjlee",
      showcaseURL: "https://storage.googleapis.com/collectr-assets/november-2024/pg-wallpapers.webp",
    },
  },
  {
    nftId: 6,
    instanceId: 5,
    startTime: 1733011200,
    endTime: 1735689599,
    month: "dec",
    year: 2024,
    split: {
      collector: 45,
      artist: 30,
      project: 25,
      splitsPage: "https://app.splits.org/accounts/0x8ac167A19Fa67F0681fFC8A41906F9b147054f3d/?chainId=8453",
    },
    editionPage: {
      price: "0.01",
      name: "Vyperwave 🐍",
      nftDescription:
        "In the metaverse, a girl and her robotic viper explore the city, providing security and safety to all the shadowy super coders. References to Vyper include popular DeFi applications, tooling, and more!",
      splitImages: [
        "https://pbs.twimg.com/profile_images/1853954632944386055/14rDdTpb_400x400.png",
        "https://pbs.twimg.com/profile_images/1805609499580850176/4aPdw4sG_400x400.jpg",
        "/images/vyper.png",
      ],
      openseaUrl:
        "https://opensea.io/assets/base/0x335a975b8122f982b0d4db9d7256e24852f0d502/6",
    },
    artistInfo: {
      name: "MACO",
      description:
        "MACO is a Peruvian illustrator who is deeply inspired by the nostalgia of 80s and 90s retro anime. His artistic journey sparked during the pandemic when he first explored pixel art as a hobby, a playful curiosity that soon evolved into a profound love for anime art.\n\nHis venture into NFTs became a path towards self-confidence, leading him to build a prescence of his own. MACO is the creator of Super Space Defenders—an NFT collection rich with original story, lore, and characters. Through SSD, he has built his own world, along with a community that cherishes his art and respects him dearly. ",
      flag: "PE",
      twitter: "https://x.com/maco9801",
      email: "maco.drawings@gmail.com",
      foundation: "https://foundation.app/@maco9801",
      skills: ["Character Design", "Character Illustration", "Graphic Design"],
    },
    projectInfo: {
      imageURL:
        "https://storage.googleapis.com/collectr-assets/december-2024/december-vyper-maco-original.webp",
      communityName: "Vyper",
      communityTwitter: "https://x.com/vyperlang",
      communityTelegram: "https://t.me/vyperlang",
      communityWebsite: "https://vyperlang.org/",
      description:
        "Vyper is a programming language that developers can use to build applications on Ethereum with simpler and safer code. Inspired by Python, Vyper code is designed in a way that is transparent, secure, and easily reviewed.\n\nLaunching as an alternative to Solidity in 2019, several established applications use Vyper, including Lido, Curve Finance, and Yearn. It's straightforward coding style reduces the chances of overseen mistakes, helping secure nearly $40 billion of value within applications. Did you know the original Uniswap V1 was built using Vyper?",
      communityMembers: [],
      hasWallpapers: true,
    },
    benefits: {
      allWallpapersCID: "bafybeiasyychuecoare2hv6bccenek4rh4hxpklgn524h3g2atsofstlpy",
      // showcaseURL: "https://storage.googleapis.com/collectr-assets/december-2024/",
    },
  },
  {
    nftId: 7,
    instanceId: 6,
    startTime: 1735689600,
    endTime: 1738367999,
    month: "jan",
    year: 2025,
    split: {
      collector: 45,
      artist: 30,
      project: 25,
      splitsPage: "https://app.splits.org/accounts/0xC85fBF25AB4adEBEBd8f14f4d6a8a2Af161893a2/?chainId=8453",
    },
    editionPage: {
      price: "0.01",
      name: "Web3 Art Alley 🦭",
      nftDescription:
        "Sealed and its art collecting community are very dear to us, their drive for both collectors and artists makes their website feel like a digital art alley. Can you spot all the references to Sealed, creators, community members, and more?",
      splitImages: [
        "https://pbs.twimg.com/profile_images/1853954632944386055/14rDdTpb_400x400.png",
        "https://pbs.twimg.com/profile_images/1849759895459094528/h6xTsbrf_400x400.jpg",
        "https://pbs.twimg.com/profile_images/1718443276238274560/8H3HGFKW_400x400.jpg",
      ],
      openseaUrl:
        "https://opensea.io/assets/base/0x335a975b8122f982b0d4db9d7256e24852f0d502/7",
    },
    artistInfo: {
      name: "loredangoo",
      description:
        "Welcome to the whimsical world of Loredango whose art explores the intersection between traditional expression and the boundlessness of digital platforms. Lore's diverse career includes freelance work for prominent clients such as Google, Traveloka, and local Indonesian efforts such as the Asian Paralympic Games, and Central Park Jakarta!\n\nCreating since 2015, Lore's art has been exhibited physically all over the world, including USA, China, Thailand, Japan, Turkey, Italy, the Philippines, and her home country of Indonesia. Lore also enjoys creating merchandise and seeing people in public wearing them. With her first release since before Covid, their new merch store will be launching soon!",
      flag: "ID",
      twitter: "https://x.com/Loredangoo",
      instagram: "https://www.instagram.com/loredangoo/",
      email: "loredangoo@gmail.com",
      sealed: "https://sealed.art/loredangoo.eth",
      skills: ["Character Illustration"],
    },
    projectInfo: {
      imageURL:
        "/images/original-dec-sealed-loredango.webp",
      communityName: "Sealed",
      communityTwitter: "https://x.com/sealed_art",
      communityWebsite: "https://sealed.art",
      description:
        "Sealed is an onchain art marketplace that prioritizes user experience and cultivates an artist-first culture. Their platform elevates the NFT art market by being dedicated to both artists and collectors, providing efficiency, fairness, and just making collecting art fun! Sealed provides tools that empower artists while enhancing discoverability for collectors.\n\nTheir list of innovative offerings includes AI powered onchain art search engine, wallet-based collector recommendations, and special vickrey private auctions with gasless anonymous bidding. Building for artists too, they provide onchain patreon-style subscriptions, profile vouching for easier trust, and even gasless edition creation.",
      communityMembers: [],
      hasWallpapers: true,
    },
    benefits: {
      allWallpapersCID: "bafybeiholxbtwsnn32dmryr7w5mzk2vn2jooe25sagmfbswmt5ttebu5bq",
      // showcaseURL: "https://storage.googleapis.com/collectr-assets/december-2024/",
    },
  },
  {
    nftId: 8,
    instanceId: 0,
    startTime: 1738368000,
    endTime: 1740787199,
    month: "feb",
    year: 2025,
    split: {
      collector: 45,
      artist: 30,
      project: 25,
      splitsPage: "https://app.splits.org/accounts/0x6AE7849E964b8286Bca54002158Da4FFa987e166/?chainId=8453",
    },
    editionPage: {
      price: "0.005",
      name: "The Journey Begins 🧙‍♂️",
      nftDescription: "Exploring the Forgotten Runiverse starts here. With a map in hand, wizards and warriers explore dungeons to collect materials, craft items, and defeat ferocious monsters! As they discover the forgotten runes, their excitement is awakened!",
      splitImages: [
        "https://pbs.twimg.com/profile_images/1853954632944386055/14rDdTpb_400x400.png",
        "https://pbs.twimg.com/profile_images/1642182116632043520/0c2gRHDL_400x400.png",
        "https://pbs.twimg.com/profile_images/1793532698679787520/cNf8tpC-_400x400.jpg",
      ],
      openseaUrl:
        "https://opensea.io/assets/base/0x335a975b8122f982b0d4db9d7256e24852f0d502/8",
    },
    artistInfo: {
      name: "Sai7",
      description:
        "Coming from a background in civil engineering and construction management, Sai7 has made a great impact in Web3 as an illustrator! Manga inspired him to illustrate, later drawing expressive characters to cross language barriers globally. Sai7 has been featured in galleries spanning across Japan and Romania, and has been very active in web3, winning many art contests!\n\nBeing based in Okinawa, Japan, Sai7 loves summer, so you can often catch him illustrating with warm colors or including the beach in his pieces. His pieces are often bursting with personality, and convey a sense of story and meaning.",
      flag: "JP",
      twitter: "https://x.com/sai7",
      portfolio: "https://xfolio.jp/portfolio/sai7/",
      email: "rainbowmaterial@gmail.com",
      sealed: "https://sealed.art/0xc4Da779262E5011Cab487E102357fBB85eF7C08F",
      skills: ["Character Illustration"],
    },
    projectInfo: {
      imageURL:
        "/images/editions/feb25-original.webp",
      communityName: "Forgotten Runes",
      communityTwitter: "https://x.com/ForgottenRunes",
      communityWebsite: "https://www.forgottenrunes.com/",
      communityOpensea: "https://opensea.io/collection/forgottenruneswizardscult",
      description:
        "Forgotten Runes has been building in the web3 entertainment space for over 3 years, most famously producing the Forgotten Runes Wizard Cult NFT collection, referred to as FRWC for short. FRWC is the foundation of their multilayered ecosystem that spans across Ethereum, Ordinals, and even traditional media such as comics, a show, an MMORPG, and more. \n\nTheir game, Forgotten Runiverse, played a big role in the creation of this art piece and is their most exciting release as of yet! The \"Book of Lore\" is another exciting piece of their story, where holders can contribute and add lore to their own FRWC NFTs, becoming a part of the brand themselves.",
      communityMembers: [],
      hasWallpapers: true,
    },
    benefits: {
      allWallpapersCID: "bafybeiai633q6yaeepkjgxnmuef2jgahuxy4zcdfqghsakj6yaeueusuiy",
      showcaseURL: "/images/showcases/wallpapersshowcaseFEB25.webp",
    },
  },
  {
    nftId: 9,
    instanceId: 1,
    startTime: 1740787200,
    endTime: 1743465599,
    month: "mar",
    year: 2025,
    split: {
      collector: 45,
      artist: 30,
      project: 25,
      splitsPage: "https://app.splits.org/accounts/0x361aaf0b8ff9b39467cedf2c40a72306013b0724/?chainId=8453",
    },
    editionPage: {
      price: "0.005",
      name: "Blood Moon Ronin 🌑",
      nftDescription: "Under the ominous glow of the blood moon, a lone hunter rises. With Oni blood burning in his veins, he stalks the shadows of Enclave, a city on the brink of ruin. Demons tear through with ruthless speed, but a true hunter knows—he must be faster. The night belongs to him.",
      splitImages: [
        "https://pbs.twimg.com/profile_images/1853954632944386055/14rDdTpb_400x400.png",
        "https://pbs.twimg.com/profile_images/1850936173017157632/r6SBFl7g_400x400.jpg",
        "https://pbs.twimg.com/profile_images/1878880545981054976/eJiG5Iuf_400x400.jpg",
      ],
      openseaUrl:
        "https://opensea.io/assets/base/0x335a975b8122f982b0d4db9d7256e24852f0d502/9",
    },
    artistInfo: {
      name: "TAKE_N1i",
      description:
        "As a former mechanic for maritime escort ships, TAKE_N1i is a Japanese character illustrator and designer with a passion for drawing mechanical illustrations. \n\nOver the course of his career, TAKE_N1i has designed characters for different companies as well as created generative designs for NFT projects of thousands of generative characters. Having experience in web2 & web3, TAKE is a seasoned artist who pursues his love for stylish and cool character designs to share with the world.",
      flag: "JP",
      twitter: "https://x.com/TAKE_NFT",
      instagram: "https://www.instagram.com/take_n1i/",
      email: "take21.art8@gmail.com",
      foundation: "https://foundation.app/@take_n1i",
      skills: ["Character Illustration", "Character Design", "Graphic Design"],
    },
    projectInfo: {
      imageURL:
        "/images/editions/mar25-original.webp",
      communityName: "ONI Force",
      communityTwitter: "https://x.com/0n1Force",
      communityWebsite: "https://0n1force.com/",
      communityOpensea: "https://opensea.io/collection/0n1-force",
      communityTelegram: "https://t.me/+nTA5RHm7PAwzMjAx",
      communityDiscord: "https://discord.gg/0n1-force",
      description:
        "ONI Force is one of the first anime themed NFT projects featuring 7,777 unique generative characters that blend Japanese folklore with cyberpunk aesthetics. Originally launching on Ethereum, the project has now gone multi-chain with the launch of their F4LL3N collection on Bitcoin Ordinals.\n\nThe ONI team has been hard at work expanding the IP through comics that are curated through community input, creating collectibles and gamified experiences, and collaborating with other brands to create opportunities for upcoming launches. They even have a Reddit avatar collaboration with a Halloween series! Don't Fade The Force!",
      communityMembers: [],
      hasWallpapers: true,
    },
    benefits: {
      allWallpapersCID: "bafybeifv2xdw66sxupzr5lr6wg2pfloqzpua7yoftguxdzo2qbz3emlm2m",
      showcaseURL: "/images/showcases/wallpapersshowcaseMAR25.webp",
    },
  },
  {
    nftId: 10,
    instanceId: 2,
    startTime: 1743465600,
    endTime: 1746057599,
    month: "apr",
    year: 2025,
    split: {
      collector: 45,
      artist: 30,
      project: 25,
      splitsPage: "https://app.splits.org/accounts/0x496D8656A65a8898e0EEf226762513deA6D26B6e/?chainId=8453",
    },
    editionPage: {
      price: "0.005",
      name: "Bom Dia 🔨",
      nftDescription: "Ouh husbandt... you didn't mint the Collectr edition and now we are homeless... April fools! Bom Dia! The kakis are taking over your screen and indoctrinating you into the cult of cute. Kiv's depiction of Kemonokaki moments and references are brimming with passion and personality! Grab some cake and let's all win together!",
      splitImages: [
        "https://pbs.twimg.com/profile_images/1853954632944386055/14rDdTpb_400x400.png",
        "https://pbs.twimg.com/profile_images/1850978078605930496/1eIJaeKH_400x400.png",
        "https://pbs.twimg.com/profile_images/1755141264788586496/ugQOdL7Z_400x400.png",
      ],
      openseaUrl:
        "https://opensea.io/assets/base/0x335a975b8122f982b0d4db9d7256e24852f0d502/10",
    },
    artistInfo: {
      name: "Kiv",
      description:
        "Kiv, an artist from São Paulo, Brazil, has been creating art for over a decade, dedicating the last two years to pixel art. His love for art began from childhood with inspiration from video games like Super Mario and Pokémon. He started his onchain journey in 2021 and was excited to sell his first NFT. In August 2023, he launched his \"64 Creatures\" project in August 2023 which allowed him fund a much-needed eye surgery.\n\nKiv is a member of he Oekaki team, and sends his love to the Oekaki community. He continues to create, working on projects like Kaijukaki and Pixcadia. Kiv is committed to creating unique art and supporting the people who have believed in him. He's excited to keep evolving as an artist, embracing new challenges and opportunities in the digital art and gaming world.",
      flag: "BR",
      twitter: "https://x.com/xdeadkiv",
      instagram: "https://www.instagram.com/deadkiv/",
      artstation: "https://www.artstation.com/deadkiv",
      email: "deadkivart@gmail.com",
      foundation: "https://foundation.app/@deadkiv",
      skills: ["Character Illustration", "Character Design", "Emotes/Stickers", "2D Animation"],
    },
    projectInfo: {
      imageURL:
        "/images/editions/apr25-original.png",
      communityName: "Kemonokaki",
      communityTwitter: "https://x.com/kemonokaki",
      communityWebsite: "https://oekakiconnect.net",
      communityOpensea: "https://opensea.io/collection/kemonokaki",
      communityDiscord: "https://discord.gg/oekaki",
      description:
        "Bom Dia! Kemonokaki is an NFT collection by Oekaki Connect, a group of artists, programmers, writers, and creatives from around the world with a shared goal of pushing the limits of interactive art. From NFT projects, to \"edutainment\", to webgames and live service games and more, Oekaki Connect is investing in themselves as they fight for Total Kaki Victory!\n\nKemonokaki launched on Base, originally as an expansion to the Oekaki Maker collection. Drawing inspiration from kemonomimi chibi art, kemono-furry fashion, and j-fashion such as Rokku and Hime Gyaru, Kemonokaki turned into its very own unique neo-chibi aesthetic and IP. With something for everyone, Kemonokaki thrives as a future forward and NEET-friendly community.",
      communityMembers: [],
      hasWallpapers: true,
    },
    benefits: {
      allWallpapersCID: "bafybeidpe4mbux5h3ubr2wmjo7sospar4cy6buplxt3yzgzewrs6t7voti",
      showcaseURL: "/images/showcases/wallpapersshowcaseAPR25.webp",
    },
  },
  // Add more editions as needed
];

export const getDefaultEdition = (): IEdition => {
  return {
    nftId: -1,
    instanceId: -1,
    startTime: 0,
    endTime: 0,
    month: "",
    year: -1,
    split: {
      collector: 0,
      project: 0,
      artist: 0,
      community: 0,
      splitsPage: "",
    },
    editionPage: {
      price: "",
      name: "",
      nftDescription: "",
      splitImages: [],
      openseaUrl: "",
    },
    artistInfo: {
      name: "",
      description: "",
      flag: "",
      twitter: "",
      instagram: "",
      email: "",
      foundation: "",
      sealed: "",
      portfolio: "",
      skills: [],
    },
    projectInfo: {
      imageURL: "",
      description: "",
      communityMembers: [],
      communityName: "",
      communityTwitter: "",
      communityInstagram: "",
      communityDiscord: "",
      communityTelegram: "",
      communityWebsite: "",
      communityOpensea: "",
      hasWallpapers: false,
    },
    benefits: {
      allWallpapersCID: "",
      showcaseURL: "",
    },
  };
};

export const editions = {
  index: async () => {
    // const response = await fetch('https://<api url here>/editions/');
    // const data = await response.json();
    // return data;

    return editions_data;
  },
  show: (id: string | undefined) => {
    // const response = await fetch('https://<api url here>/editions/${id}');
    // const data = await response.json();
    // return data;

    if (id === undefined || parseInt(id) >= editions_data.length) {
      return getDefaultEdition();
    }

    return editions_data[parseInt(id)];
  },
};
