import { useState, useEffect } from "react";
import styled from "styled-components";

const StyledUsdPrice = styled.div`
  color: #9b9b9b;
  font-size: 14px;
  margin-bottom: 2px;
  font-family: monospace;
`;


const ETHUsdPrice = ({ quantity = 1 }) => {
  const [price, setPrice] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const res = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd");
        const data = await res.json();
        setPrice(data.ethereum.usd);
      } catch (error) {
        console.error("Error fetching ETH price:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPrice();
    const interval = setInterval(fetchPrice, 60000 * 5); // Auto refresh every 5 minutes

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const usdPrice = price ? (price * 0.005 * quantity).toFixed(2) : "...";

  return <StyledUsdPrice>{loading ? "Loading..." : `$${usdPrice}`}</StyledUsdPrice>;
};

export default ETHUsdPrice;
