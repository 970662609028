export const ercABI = [
	{
		inputs: [
			{ internalType: "address", name: "account", type: "address" },
			{ internalType: "uint256", name: "id", type: "uint256" },
		],
		name: "balanceOf",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function"
	},
	{ 
		inputs: [
			{ internalType: "uint256", name: "tokenId", type: "uint256" }
		], 
		name: "totalSupply", 
		outputs: [
			{ internalType: "uint256", name: "", type: "uint256" }
		], 
		stateMutability: "view", type: "function" 
	}
] as const;
