import { useState, useEffect } from "react";
import { useAccount } from "wagmi";
import { useReadContracts } from "wagmi";
import { ercABI } from "@contracts/ERC1155Creator";
import { IEdition } from "@src/context";
import { IS_MAINNET } from "@src/utils/constants";
import { base, baseSepolia } from "viem/chains";

interface MintStatus {
  [key: string]: boolean;
}

interface ContractCallResult {
  status: "success" | "failure";
  result?: BigInt;
}

const NFT_CONTRACT = IS_MAINNET ? process.env.REACT_APP_NFT_ADDRESS : process.env.REACT_APP_TEST_NFT_ADDRESS;

const useMintStatuses = (featuredEditions: IEdition[]) => {
  const { address, isConnected } = useAccount();
  const [mintStatuses, setMintStatuses] = useState<MintStatus>({});

  const contractAddress = NFT_CONTRACT as `0x${string}`;
  const contracts = featuredEditions.map((edition) => ({
    chainId: IS_MAINNET ? base.id : baseSepolia.id,
    address: contractAddress,
    abi: ercABI,
    functionName: "balanceOf",
    args: [address as `0x${string}`, BigInt(edition.instanceId + 1)],
  }));

  const { refetch } = useReadContracts({
    contracts,
  });

  useEffect(() => {
    const fetchMintStatuses = async () => {
      if (!isConnected || !address || featuredEditions.length === 0) {
        setMintStatuses({});
        return;
      }

      try {
        const results = await refetch();

        if (results.status === "success" && Array.isArray(results.data)) {
          const status: MintStatus = {};
          (results.data as ContractCallResult[]).forEach((result, index) => {
            if (
              result.status === "success" &&
              typeof result.result === "bigint"
            ) {
              status[String(featuredEditions[index].instanceId)] =
                Number(result.result) > 0;
            } else {
              status[String(featuredEditions[index].instanceId)] = false;
            }
          });

          setMintStatuses(status);
        }
      } catch (error) {
        console.error("Error fetching mint statuses:", error);
      }
    };

    fetchMintStatuses();
  }, [isConnected, address, featuredEditions, refetch]);

  return mintStatuses;
};

export default useMintStatuses;
